<template>
  <div>
    <v-container>
      <v-row v-if="getProductBySlug(slug)" v-bind="(product = getProductBySlug(slug))">
        <v-col cols="12" md="6">
          <div v-for="image in product.images" :key="image">
            <v-img :src="image"></v-img>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <v-card flat class="pa-5">
            <v-toolbar flat>
              <v-toolbar-title class="headline font-weight-bold">
                <div class="body-1 font-weight-light">{{ product.category.name }}</div>
                {{ product.name }}
              </v-toolbar-title>
            </v-toolbar>
            <v-divider class="my-6"></v-divider>
            <v-simple-table>
              <tbody>
                <tr>
                  <td class="title primary--text pb-2">Price:</td>
                  <td class="title primary--text pb-2">
                    {{ product.price | currency({
                    fractionCount: 0
                    }) }}
                  </td>
                </tr>
                <tr>
                  <td>SKU:</td>
                  <td>{{ product.sku }}</td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-divider class="mt-12 mb-8"></v-divider>
            <v-card-text>
              <v-btn
                v-if="!addCartButton"
                @click="addToCart(product)"
                color="primary"
                large
                block
              >Add to Cart</v-btn>
              <v-btn v-else to="/cart" color="primary" large block>Go to Cart</v-btn>
            </v-card-text>
          </v-card>
          <v-divider></v-divider>

          <v-card flat v-if="product.features.length">
            <v-card-text>
              <v-toolbar flat>
                <v-toolbar-title>Details</v-toolbar-title>
              </v-toolbar>
              <v-card-text v-html="product.description"></v-card-text>
              <v-toolbar flat>
                <v-toolbar-title>Features</v-toolbar-title>
              </v-toolbar>
              <v-simple-table>
                <tbody>
                  <tr v-for="item in product.features" :key="item.name">
                    <td>{{ item.name }}</td>
                    <td>{{ item.option }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      slug: this.$route.params.slug
    };
  },
  computed: {
    ...mapGetters(["getProductBySlug", "cartProducts"]),
    addCartButton() {
      return this.checkProductInCart();
    }
  },
  methods: {
    ...mapActions(["bind", "getSelectedProduct"]),

    addToCart(product) {
      //console.log(product)
      this.getSelectedProduct(product);
    },
    checkProductInCart() {
      return this.cartProducts.some(product => product.slug == this.slug);
    }
  },
  created() {
    this.bind({
      collection: "products",
      data: "products"
    });
  }
};
</script>

<style></style>
